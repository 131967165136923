import React from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import CorporateForm from '../Page/CorporateForm/CorporateForm'
import CorporateConfirmed from '../Page/CorporateConfirmed/CorporateConfirmed'
import CreateAccount from '../Page/CreateAccount/CreateAccount'
import CorporateLogin from '../Page/CorporateLogin/CorporateLogin'
import CorporateForgot from '../Page/CorporateForgot/CorporateForgot'
import CorporateVerification from '../Page/CorporateVerification/CorporateVerification'
import CorporateResetPass from '../Page/CorporateResetPass/CorporateResetPass'
import CorporateDashboard from '../Page/CorporateDashboard/CorporateDashboard'
import MainLayout from '../Layouts/MainLayout/MainLayout'
import Requests from '../Page/Requests/Requests'
import Calender from '../Page/Calender/Calender'
import RequestDetails from '../Page/RequestDetails/RequestDetails'
import Employees from '../Page/Employees/Employees'
import AddEmployee from '../Page/AddEmployee/AddEmployee'
import UpdateEmployee from '../Page/UpdateEmployee/UpdateEmployee'
import EmployeeDetails from '../Page/EmployeeDetails/EmployeeDetails'
import Reports from '../Page/Reports/Reports'
import Invoices from '../Page/Invoices/Invoices'
import InvoiceDetails from '../Page/InvoiceDetails/InvoiceDetails'
import Setting from '../Page/Setting/Setting'
import ReportDetails from '../Page/ReportDetails/ReportDetails'
import Messages from '../Page/Messages/Messages'

function RouterData() {
    return (
        <>
            <BrowserRouter>
                <Routes>
                    <Route path='/' element={<CorporateForm />} />
                    <Route path='/corporateform' element={<CorporateForm />} />
                    <Route path='/confirmedform' element={<CorporateConfirmed />} />
                    <Route path='/createaccount/:id' element={<CreateAccount />} />
                    <Route path='/corporatelogin' element={<CorporateLogin />} />
                    <Route path='/corporateforgot' element={<CorporateForgot />} />
                    <Route path='/corporateverification' element={<CorporateVerification />} />
                    <Route path='/corporatereset' element={<CorporateResetPass />} />
                    <Route path='/corporatedashboard' element={<MainLayout><CorporateDashboard /></MainLayout>} />
                    <Route path='/calendar' element={<MainLayout><Calender /></MainLayout>} />
                    <Route path='/requests' element={<MainLayout><Requests /></MainLayout>} />
                    <Route path='/requests/requestsdetails' element={<MainLayout><RequestDetails /></MainLayout>} />
                    <Route path='/employees' element={<MainLayout><Employees /></MainLayout>} />
                    <Route path='/employees/addemployee' element={<MainLayout><AddEmployee /></MainLayout>} />
                    <Route path='/employees/updateemployee' element={<MainLayout><UpdateEmployee /></MainLayout>} />
                    <Route path='/employees/employeedetails' element={<MainLayout><EmployeeDetails /></MainLayout>} />
                    <Route path='/reports' element={<MainLayout><Reports /></MainLayout>} />
                    <Route path='/invoices' element={<MainLayout><Invoices /></MainLayout>} />
                    <Route path='/invoices/invoicedetails' element={<MainLayout><InvoiceDetails /></MainLayout>} />
                    <Route path='/setting' element={<MainLayout><Setting /></MainLayout>} />
                    <Route path='/reportDetails/:id' element={<ReportDetails />} />
                    <Route path='/messages' element={<MainLayout><Messages /></MainLayout>} />
                </Routes>
            </BrowserRouter>
        </>
    )
}

export default RouterData