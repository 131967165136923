import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBbDFrKkwtrMvQo1JksELtIo3V3eX7k6ew",
  authDomain: "welo-web.firebaseapp.com",
  projectId: "welo-web",
  storageBucket: "welo-web.appspot.com",
  messagingSenderId: "700873866626",
  appId: "1:700873866626:web:797c8c47ee966a395a639b",
  measurementId: "G-KJ2QB1KXKX",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase Messaging
const messaging = getMessaging(app); // Ensure messaging is initialized here

// Function to get the FCM registration token
export const getFCMToken = async () => {
  try {
    const currentToken = await getToken(messaging, {
      vapidKey: 'BJD7HB3xTFYasjVUid6vXpa-7z-xMBPJnqzPS7eJixkCelALJpgQPHV3lzCqgsz5tz4leYsscKfHxwUSE5F6MIA',
    });
    if (currentToken) {
      return currentToken;
    } else {
      console.log("No registration token available. Request permission to generate one.");
      return null;
    }
  } catch (error) {
    console.log("An error occurred while retrieving token. ", error);
    return null;
  }
};

// Function to handle messages when the app is open
export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
  });
