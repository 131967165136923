import React, { useEffect, useRef, useState } from 'react'
import back from "../../Assets/Image/corporate/back.png"
import { useLocation, useNavigate } from 'react-router-dom'
import search from "../../Assets/Image/corporate/Search.png"
import noImage from "../../Assets/Image/corporate/user.png";
import men1 from "../../Assets/Image/corporate/men6.png"
import men2 from "../../Assets/Image/corporate/men7.png"
import men3 from "../../Assets/Image/corporate/men8.png"
import men4 from "../../Assets/Image/corporate/men9.png"
import men5 from "../../Assets/Image/corporate/men10.png"
import men6 from "../../Assets/Image/corporate/men11.png"
import men7 from "../../Assets/Image/corporate/men12.png"
import men8 from "../../Assets/Image/corporate/men13.png"
import pdf from "../../Assets/Image/corporate/pdf.png"
import view from "../../Assets/Image/corporate/view.png"
import nodata from "../../Assets/Image/corporate/nodata.png"
import background from "../../Assets/Image/corporate/background1.png"
import clock from "../../Assets/Image/corporate/clock 1.png"
import welo from "../../Assets/Image/corporate/welowhite.png"
import Dialog from '../../Base-Component/Dialog/Dialog'
import FormSelect from '../../Base-Component/FormSelect/FormSelect'
import moment from 'moment'
import toast from 'react-hot-toast'
import Cookies from 'js-cookie';
import { cancelRequests, listAssignHealthworker, listReports } from '../../Services/ApiServices'
import { IMG_URL } from '../../Services/Api'
import { Bars, Circles } from 'react-loader-spinner';

function RequestDetails() {

    const navigate = useNavigate()
    const location = useLocation()
    const scrollRef = useRef(null);
    const [loader, setLoader] = useState(false)
    const [isDragging, setIsDragging] = useState(false);
    const [isModalOpens, setModalOpens] = useState(false)
    const [isCancel, setIsCancel] = useState(false)
    const [isLoading, setIsLoading] = useState(false)

    const [startX, setStartX] = useState(0);
    const [scrollLeft, setScrollLeft] = useState(0);
    const [pageNo, setPageNo] = useState(1)
    const [totalPage, setTotalPage] = useState("")
    const [pageNos, setPageNos] = useState(1)
    const [totalPages, setTotalPages] = useState("")

    const [searchData, setSearchData] = useState("")
    const [handelSelect, setHandleSelect] = useState("")

    const [userData, setUserData] = useState([])

    const [assigneData, setAssigneData] = useState([])

    let usersData;
    try {
        const { Data } = location.state;
        usersData = Data
    } catch (error) {
        console.log("err")
    }

    const handleAssignList = () => {
        let obj = {
            pageNo: pageNos,
            requestId: usersData.requestId,
            type: "ASSIGN"
        }
        listAssignHealthworker(obj)
            .then((res) => {
                if (res.status == 200) {
                    const data = res.data.info;
                    setAssigneData(data)
                    setTotalPages(res.data.totalPage)
                }
            })
            .catch((err) => {
                console.log("err >>", err);
                if (err.response.status == 401) {
                    localStorage.removeItem("corporate_token")
                    navigate("/corporatelogin")
                } else {
                    toast.error(err.response.data.message)
                }
            })
    }

    const handleListRequest = () => {
        setLoader(true)
        let param = {
            pageNo: pageNo,
            requestId: usersData.requestId
        }
        listReports(param)
            .then((res) => {
                if (res.status == 200) {
                    const data = res.data.info;
                    setUserData(data)
                    setTotalPage(res.data.totalPage)
                }
                setLoader(false)
            })
            .catch((err) => {
                if (err.response.status == 401) {
                    localStorage.removeItem("corporate_token")
                    navigate("/corporatelogin")
                } else {
                    toast.error(err.response.data.message)
                }
                setLoader(false)
            })
    }

    useEffect(() => {
        handleAssignList()
    }, [pageNos])

    useEffect(() => {
        handleListRequest()
    }, [pageNo])

    const assignPaginationButton = () => {
        const maxPagesToShow = 3;
        const buttons = [];
        const startPage = Math.max(1, pageNos - Math.floor(maxPagesToShow / 2));
        const endPage = Math.min(totalPages, startPage + maxPagesToShow - 1);

        for (let i = startPage; i <= endPage; i++) {
            buttons.push(
                <button
                    key={i}
                    onClick={() => handleAssignChange(i)}
                    className={i === pageNos ? 'px-4 py-[7px] mx-1 bg-[#EA5F5F1A] rounded-md text-[#EA5F5F]' : 'px-4 py-[7px] mx-1 text-[#EA5F5F]'}
                >
                    {i}
                </button>
            );
        }
        return buttons;
    };

    const handleAssignChange = (page) => {
        if (page > 0 && page <= totalPages) {
            setPageNos(page);
        }
    };

    const renderPaginationButtonss = () => {
        const maxPagesToShow = 3;
        const buttons = [];
        const startPage = Math.max(1, pageNo - Math.floor(maxPagesToShow / 2));
        const endPage = Math.min(totalPage, startPage + maxPagesToShow - 1);

        for (let i = startPage; i <= endPage; i++) {
            buttons.push(
                <button
                    key={i}
                    onClick={() => handlePageChange(i)}
                    className={i === pageNo ? 'px-4 py-[7px] mx-1 bg-[#EA5F5F1A] rounded-md text-[#EA5F5F]' : 'px-4 py-[7px] mx-1 text-[#EA5F5F]'}
                >
                    {i}
                </button>
            );
        }
        return buttons;
    };

    const handlePageChange = (page) => {
        if (page > 0 && page <= totalPage) {
            setPageNo(page);
        }
    };

    const startDragging = (e) => {
        setIsDragging(true);
        setStartX(e.pageX - scrollRef.current.offsetLeft);
        setScrollLeft(scrollRef.current.scrollLeft);
        document.body.style.userSelect = 'none'; // Disable text selection
    };

    const stopDragging = () => {
        setIsDragging(false);
        document.body.style.userSelect = ''; // Re-enable text selection
    };

    const onDragging = (e) => {
        if (!isDragging) return;
        e.preventDefault();
        const x = e.pageX - scrollRef.current.offsetLeft;
        const walk = (x - startX) * 2; // scroll-fast
        scrollRef.current.scrollLeft = scrollLeft - walk;
    };

    let servicess = [];
    const address = usersData.companyAddress;
    const services = usersData.requestServices;

    for (let i = 0; i < services.length; i++) {
        const serviceName = services[i].healthService.serviceName;
        servicess.push(serviceName);
    }

    const handleDetails = (id) => {
        navigate("/reportDetails/" + id)
    }

    const handleRequestStatus = () => {
        setIsLoading(true)
        const param = { requestId: usersData.requestId }
        cancelRequests(param)
            .then((res) => {
                if (res.status == 200) {
                    setIsCancel(false)
                    usersData.requestStatus = "CANCELLED";
                }
                setIsLoading(false)
            })
            .catch((err) => {
                console.log("err >>", err);
                if (err.response.status == 401) {
                    localStorage.removeItem("corporate_token")
                    navigate("/corporatelogin")
                } else {
                    toast.error(err.response.data.message)
                }
                setIsLoading(false)
            })
    }

    return (
        <div className='md:m-4 m-1'>
            <div className='flex items-center cursor-pointer fontNew mb-4'>
                <img src={back} onClick={() => { navigate("/requests") }} className='w-4' alt="" />
                <p onClick={() => { navigate("/requests") }} className='text-base font-medium ms-3'>Go back</p>
            </div>

            <div className='grid grid-cols-12 gap-3'>
                {
                    loader ?
                        <div className='flex justify-center items-center col-span-12 h-[70vh]'>
                            <Bars
                                height="35"
                                width="35"
                                color="#EA5F5F"
                                ariaLabel="bars-loading"
                                wrapperStyle={{}}
                                wrapperClass=""
                                visible={true}
                            />
                        </div>
                        :
                        <>
                            <div className='2xl:col-span-8 xl:col-span-8 lg:col-span-12 md:col-span-12 col-span-12 fontNew duration-300 cursor-pointer'>
                                <div className='bg-white sm:px-7 px-3 py-3 rounded-2xl'>
                                    <div className='flex flex-wrap-reverse justify-between border-b-2 border-dashed py-5'>
                                        <div className='flex flex-wrap'>
                                            <div>
                                                <p className='text-base font-semibold pb-1'># {usersData.requestId || "0"}</p>
                                                <p className='text-sm font-medium text-gray-400'>{moment(usersData.createdAt).format("LLL") || ""}</p>
                                            </div>
                                            <div className='lg:ms-14 md:ms-7 ms-4'>
                                                {
                                                    usersData.requestStatus == "PENDING" ?
                                                        <button className='bg-[#f2994a1a] py-2 px-3 text-[#F2A205] text-sm rounded-lg'>Pending</button>
                                                        : usersData.requestStatus == "CONFIRMED" ?
                                                            <button className='bg-[#7424f21a] py-2 px-3 text-[#7424F2] text-sm rounded-lg'>Confirmed</button>
                                                            : usersData.requestStatus == "INPROGRESS" ?
                                                                <button className='bg-[#2336E41A] py-2 px-3 text-[#2336E4] text-sm rounded-lg'>In-Progress</button>
                                                                : usersData.requestStatus == "COMPLETED" ?
                                                                    <button className='bg-[#00987C29] py-2 px-3 text-[#00987C] text-sm rounded-lg'>Completed</button>
                                                                    : <button className='bg-[#e423231a] py-2 px-3 text-[#E42323] text-sm rounded-lg'>Cancelled</button>
                                                }
                                            </div>
                                        </div>
                                        <div className='sm:mb-0 mb-3'>
                                            {
                                                usersData.requestStatus == "INPROGRESS" || usersData.requestStatus == "CANCELLED" ?
                                                    ""
                                                    :
                                                    usersData.requestStatus == "COMPLETED" ?
                                                        <button onClick={() => setModalOpens(true)} className='bg-[#5570F1] text-white flex items-center py-2 px-3 rounded-lg'>
                                                            Write Feedback
                                                        </button>
                                                        :
                                                        <button onClick={() => setIsCancel(true)} className='bg-[#EA5F5F] text-white flex items-center py-2 px-3 rounded-lg'>
                                                            Cancel Request
                                                        </button>
                                            }
                                        </div>
                                    </div>
                                    <div className='grid grid-cols-12 mt-2'>
                                        <div className='2xl:col-span-6 xl:col-span-6 lg:col-span-6 md:col-span-6 col-span-12 bg-white rounded-2xl fontNew duration-300 my-2 cursor-pointer'>
                                            <div className='flex justify-start mt-2'>
                                                <div>
                                                    <p className='text-base text-[#6C6D7B] mt-2'>Visit Date:</p>
                                                    <p className='text-base font-medium pb-1 mt-2'>{moment(usersData.requestDate).format("LL") || ""}</p>
                                                </div>
                                                <div className='ms-10'>
                                                    <p className='text-base text-[#6C6D7B] mt-2'>Visit Time:</p>
                                                    <p className='text-base font-medium pb-1 mt-2'>{usersData.startTime} - {usersData.endTime}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='2xl:col-span-4 xl:col-span-4 lg:col-span-12 md:col-span-12 col-span-12 bg-white rounded-2xl fontNew duration-300 my-2 cursor-pointer'>
                                            <div className='flex justify-start mt-2'>
                                                <div className=''>
                                                    <p className='text-base text-[#6C6D7B] my-2'>No. of employees:</p>
                                                    <p className='text-base font-medium pb-1 my-2'>{usersData.NoOfEmployee || "0"}</p>
                                                </div>
                                                <div className='ms-10'>
                                                    <p className='text-base text-[#6C6D7B] my-2'>Cost:</p>
                                                    <p className='text-base font-medium pb-1 my-2'>R {usersData.estimatedCost || "0"}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='2xl:col-span-6 xl:col-span-6 lg:col-span-6 md:col-span-6 col-span-12 bg-white rounded-2xl fontNew duration-300 my-2 cursor-pointer'>
                                            <p className='text-base text-[#6C6D7B] my-2'>Service Required:</p>
                                            <p className='text-base font-medium pb-1 my-2'>{servicess.toString() || ""}</p>
                                        </div>
                                        <div className='2xl:col-span-6 xl:col-span-6 lg:col-span-6 md:col-span-6 col-span-12 bg-white rounded-2xl fontNew duration-300 my-2 cursor-pointer'>
                                            <p className='text-base text-[#6C6D7B] my-2'>Site:</p>
                                            <p className='text-base font-medium pb-1 my-2'>{`${address.aptAddress}, ${address.streetAddress}, ${address.city}, ${address.state}-${address.zipCode}`}</p>
                                        </div>
                                        <div className='2xl:col-span-6 xl:col-span-6 lg:col-span-6 md:col-span-6 col-span-12 bg-white rounded-2xl fontNew duration-300 my-2 cursor-pointer'>
                                            <p className='text-base text-[#6C6D7B] my-2'>Additional requests/preferences</p>
                                            <p className='text-base font-medium pb-1 my-2'>{usersData.specifyText || ""}</p>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div className='flex flex-wrap justify-between items-center m-2'>
                                        <h1 className='text-xl font-medium m-2'>Visit Requests</h1>
                                        <div className='flex sm:w-70 w-full h-10 px-4 rounded-xl bg-white my-3 items-center'>
                                            <input
                                                type="text"
                                                required
                                                className='bg-transparent border-none outline-none sm:w-70 w-full h-10 text-sm fontNew'
                                                placeholder='Search...'
                                                name='search_text'
                                                value={searchData}
                                                onChange={(e) => setSearchData(e.target.value)}
                                            />
                                            <img src={search} className='p-2 w-10 cursor-pointer' alt="" />
                                        </div>
                                    </div>
                                    {
                                        userData.length == 0 ?
                                            <div className='w-full h-[50vh] justify-center flex items-center'>
                                                <img src={nodata} className='w-28' alt="" />
                                            </div>
                                            :
                                            <>
                                                <div className="col-span-12 overflow-auto">
                                                    <div
                                                        className='w-full overflow-auto'
                                                        onMouseDown={startDragging}
                                                        onMouseLeave={stopDragging}
                                                        onMouseUp={stopDragging}
                                                        onMouseMove={onDragging}
                                                        ref={scrollRef}
                                                    >
                                                        <table className='w-full border-separate border-spacing-y-3'>
                                                            <tbody>
                                                                <tr className='bg-white'>
                                                                    <th className='py-4 px-5 text-base font-medium whitespace-nowrap text-start first:rounded-l-lg last:rounded-r-lg'>
                                                                        ID
                                                                    </th>
                                                                    <th className='py-4 px-3 text-base font-medium whitespace-nowrap text-start first:rounded-l-lg last:rounded-r-lg'>
                                                                        Employee
                                                                    </th>
                                                                    <th className='py-4 px-3 text-base font-medium whitespace-nowrap text-start first:rounded-l-lg last:rounded-r-lg'>
                                                                        Healthworker
                                                                    </th>
                                                                    <th className='py-4 px-3 text-base font-medium whitespace-nowrap text-start first:rounded-l-lg last:rounded-r-lg'>Gender</th>
                                                                    <th className='py-4 px-3 text-base font-medium whitespace-nowrap text-start first:rounded-l-lg last:rounded-r-lg'>Service</th>
                                                                    <th className='py-4 px-3 text-base font-medium whitespace-nowrap text-start first:rounded-l-lg last:rounded-r-lg'>Report</th>
                                                                    <th className='py-4 px-3 text-base font-medium whitespace-nowrap text-start first:rounded-l-lg last:rounded-r-lg'>Status</th>
                                                                </tr>
                                                                {
                                                                    userData.map((item, index) => {
                                                                        const user = item.User;
                                                                        const medial = item?.medicalCertificate?.healthworkerDetails;
                                                                        const request = item?.medicalCertificate?.requestServices;
                                                                        return (
                                                                            <tr key={index} className='bg-white font-medium'>
                                                                                <td className='text-start px-4 py-3 first:rounded-l-lg last:rounded-r-lg'>
                                                                                    <div className='whitespace-nowrap'>
                                                                                        {item.requestEmployeeId || ""}
                                                                                    </div>
                                                                                </td>
                                                                                <td className='text-start px-4 py-3 first:rounded-l-lg last:rounded-r-lg'>
                                                                                    <div className='whitespace-nowrap w-52 flex items-center'>
                                                                                        <img
                                                                                            src={user && user.profilePic ? IMG_URL + user.profilePic : noImage}
                                                                                            className='w-10 h-10 rounded-full object-cover'
                                                                                            crossOrigin='anonymous'
                                                                                            alt=""
                                                                                        />
                                                                                        <p className='text-base ms-3'>{user ? `${user.firstName || ""} ${user.lastName || ""}` : ""}</p>
                                                                                    </div>
                                                                                </td>
                                                                                <td className='text-start px-4 py-3 first:rounded-l-lg last:rounded-r-lg'>
                                                                                    <div className='whitespace-nowrap w-52 flex items-center'>
                                                                                        <img
                                                                                            src={medial && medial.profilePic ? IMG_URL + medial.profilePic : noImage}
                                                                                            className='w-10 h-10 rounded-full object-cover'
                                                                                            crossOrigin='anonymous'
                                                                                            alt=""
                                                                                        />
                                                                                        <p className='text-base ms-3'>{medial ? `${medial.firstName || "-"} ${medial.lastName || "-"}` : ""}</p>
                                                                                    </div>
                                                                                </td>
                                                                                <td className='text-start px-4 py-3 first:rounded-l-lg last:rounded-r-lg'>
                                                                                    <div className='whitespace-nowrap'>
                                                                                        {user ? user.employeeGender || "" : ""}
                                                                                    </div>
                                                                                </td>
                                                                                <td className='text-start px-4 py-3 first:rounded-l-lg last:rounded-r-lg'>
                                                                                    <div className='whitespace-nowrap'>
                                                                                        {request ? request[0].healthService.serviceName : ""}
                                                                                    </div>
                                                                                </td>
                                                                                <td className='text-start px-4 py-3 first:rounded-l-lg last:rounded-r-lg'>
                                                                                    {
                                                                                        item.medicalCertificate == null ? "-" :
                                                                                            <div className='whitespace-nowrap w-32 flex items-center cursor-pointer' onClick={(e) => handleDetails(item.requestEmployeeId)}>
                                                                                                <img src={pdf} className='w-7' alt="" />
                                                                                                <p className='text-base ms-3 text-[#EA5F5F]'>View Report</p>
                                                                                            </div>
                                                                                    }
                                                                                </td>
                                                                                <td className='text-start px-4 py-3 first:rounded-l-lg last:rounded-r-lg'>
                                                                                    {
                                                                                        usersData && usersData.requestStatus === "COMPLETED" ?
                                                                                            <div className='whitespace-nowrap text-[#00987C]'>
                                                                                                Completed
                                                                                            </div>
                                                                                            :
                                                                                            "Wait"
                                                                                    }
                                                                                </td>
                                                                            </tr>
                                                                        );
                                                                    })
                                                                }
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                                <div className='col-span-12 flex flex-wrap items-center sm:justify-between justify-center bg-white py-4 sm:px-8 px-0 rounded-lg'>
                                                    <div className='flex items-center'>
                                                        <p>Show Result</p>
                                                        <div>
                                                            <FormSelect id="page" name="page_no" className="outline-none px-2 ms-3" value={handelSelect} onChange={(e) => setHandleSelect(e.target.value)}>
                                                                <option value="5">5</option>
                                                                <option value="10">10</option>
                                                                <option value="15">15</option>
                                                            </FormSelect>
                                                        </div>
                                                    </div>
                                                    <div className='flex items-center cursor-pointer'>
                                                        <p onClick={() => handlePageChange(pageNo - 1)} disabled={pageNo === 1}>
                                                            Previous
                                                        </p>
                                                        <div className="flex items-center mx-6">
                                                            {renderPaginationButtonss()}
                                                        </div>
                                                        <p onClick={() => handlePageChange(pageNo + 1)} disabled={pageNo === totalPage}>
                                                            Next
                                                        </p>
                                                    </div>
                                                </div>
                                            </>
                                    }
                                </div>
                            </div>
                            <div className='2xl:col-span-4 xl:col-span-4 lg:col-span-12 md:col-span-12 col-span-12 fontNew duration-300 cursor-pointer'>
                                <div className='bg-white rounded-xl'>
                                    <div className='w-full h-32 bg-center bg-cover text-white flex flex-col items-start justify-center px-8 rounded-t-xl' style={{ backgroundImage: `url(${background})` }}>
                                        <p className='text-2xl font-medium pb-2'>Assigned</p>
                                        <p>Healthcare Workers for medicals</p>
                                    </div>
                                    {
                                        assigneData.length == 0 ?
                                            <div className='text-center px-4 py-8'>
                                                <div className='flex justify-center'>
                                                    <img src={clock} className='w-28' alt="" />
                                                </div>
                                                <p className='text-gray-400 mt-7'>Waiting for the assignment of the <br /> healthcare workers</p>
                                            </div>
                                            :
                                            <div className='md:px-6 px-3 py-3'>
                                                {
                                                    assigneData.map((items, index) => {
                                                        const data = items.assignedUser
                                                        return (
                                                            <div key={index} className='flex items-center justify-between py-3 cursor-pointer'>
                                                                <div className='flex items-center'>
                                                                    <img src={data.profilePic ? IMG_URL + data.profilePic : noImage} crossOrigin='anonymous' className='w-14 h-14 object-cover rounded-full' alt="" />
                                                                    <div className='ms-4'>
                                                                        <p className='md:text-base text-[15px] font-medium'>{data.firstName || ""} {data.lastNames || ""}</p>
                                                                        <p className='md:text-sm text-[14px] text-gray-500'>{data.professionalPractice || ""}</p>
                                                                    </div>
                                                                </div>
                                                                <div>
                                                                    {
                                                                        items.isAccept == "PENDING" ?
                                                                            <button className='bg-[#f2994a1a] py-2 px-3 text-[#F2A205] text-sm rounded-lg'>Pending</button>
                                                                            :
                                                                            <button className='bg-[#00987C29] py-2 px-3 text-[#00987C] text-sm rounded-lg'>Accepted</button>
                                                                    }
                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                                }
                                                <div className='col-span-12 flex items-center sm:justify-end justify-center bg-white py-4 sm:px-8 px-0 mt-4 rounded-lg'>
                                                    <div className='flex items-center cursor-pointer'>
                                                        <p onClick={() => handleAssignChange(pageNos - 1)} disabled={pageNos === 1}>
                                                            Previous
                                                        </p>
                                                        <div className="flex items-center mx-6">
                                                            {assignPaginationButton()}
                                                        </div>
                                                        <p onClick={() => handleAssignChange(pageNos + 1)} disabled={pageNos === totalPages}>
                                                            Next
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                    }
                                </div>
                                {/* <div className='bg-white rounded-xl p-5 my-3'>
                                    <h1 className='text-2xl font-medium'>Pre-visit checklist</h1>
                                    <h3 className='text-lg font-medium my-4'>General Preparations:</h3>
                                    <div className='my-2'>
                                        <h4 className='font-medium'>Confrm Appointment Details:</h4>
                                        <ul className='my-2'>
                                            <li className='text-gray-400 ms-5'>Verify the date, time, and services scheduled for the visit.</li>
                                        </ul>
                                    </div>
                                    <div className='my-2'>
                                        <h4 className='font-medium'>Employee Participation:</h4>
                                        <ul className='my-2'>
                                            <li className='text-gray-400 ms-5'>Ensure employee sign-up is complete.</li>
                                            <li className='text-gray-400 ms-5'>Confirm the number of participants and provide a final headcount to the service provider.</li>
                                        </ul>
                                    </div>
                                    <div className='my-2'>
                                        <h4 className='font-medium'>Venue Preparation:</h4>
                                        <ul className='my-2'>
                                            <li className='text-gray-400 ms-5'>Reserve space for the wellness day (e.g., conference room, outdoor area).</li>
                                            <li className='text-gray-400 ms-5'>Ensure the venue is clean, accessible, and set up according to the provider's specifications.</li>
                                        </ul>
                                    </div>
                                </div> */}
                            </div>
                        </>
                }
            </div>

            <Dialog
                open={isModalOpens}
                onClose={() => setModalOpens(false)}
                size='xxl'
            >
                <Dialog.Panel>
                    <Dialog.Description className="rounded-none fontNew border-2 sm:w-auto w-[360px] sm:h-[690px] h-[580px] border-white sm:overflow-none overflow-auto">
                        <div className='sm:block hidden'>
                            <div className="grid grid-cols-12" >
                                <div className='col-span-4 bg-[#EA5F5F] flex items-center justify-center px-8 text-white h-[686px]'>
                                    <img src={welo} className='w-40' alt="" />
                                </div>
                                <div className='col-span-8'>
                                    <div className='lg:mx-24 mx-10'>
                                        <div>
                                            <div className='mb-10 mt-20'>
                                                <h1 className='text-2xl font-medium '>Share your feedback</h1>
                                                <p className='text-gray-500 text-sm pt-2'>Your opinion matters more than you think. We're listening, not just to your words, but to what you're really saying.</p>
                                            </div>
                                            <div>
                                                <div>
                                                    <p className='fontNew text-sm font-medium mt-3'>Your Feedback</p>
                                                    <div className='mt-3 mb-6'>
                                                        <div className='flex py-1 px-4 rounded-md border-[1px] border-slate-300 my-3 items-center hover:border-[#00987C]'>
                                                            <textarea name="" rows={7} placeholder='Enter' className='w-full outline-none py-3' id=""></textarea>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='flex justify-between mb-10 mt-40'>
                                                <button className='bg-[#F4F5FA] py-3 px-10 text-sm rounded-lg' onClick={() => setModalOpens(false)}>Close</button>
                                                <button className='bg-[#00987C] py-3 px-10 text-sm rounded-lg text-white' onClick={() => setModalOpens(false)}>Submit</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='sm:hidden block sm:p-8 p-3'>
                            <div>
                                <div>
                                    <div className='mb-5'>
                                        <h1 className='text-lg font-medium '>Share your feedback</h1>
                                        <p className='text-gray-500 text-sm pt-2'>Your opinion matters more than you think. We're listening, not just to your words, but to what you're really saying.</p>
                                    </div>
                                    <div>
                                        <div>
                                            <p className='fontNew text-sm font-medium mt-3'>Your Feedback</p>
                                            <div className='mt-3 mb-6'>
                                                <textarea name="" rows={7} placeholder='Enter' className='border-2 px-4 py-3 rounded-md w-full outline-[#00987C]' id=""></textarea>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='flex justify-between mt-[150px]'>
                                        <button className='bg-[#F4F5FA] py-3 px-10 text-sm rounded-lg' onClick={() => setModalOpens(false)}>Close</button>
                                        <button className='bg-[#00987C] py-3 px-10 text-sm rounded-lg text-white' onClick={() => setModalOpens(false)}>Submit</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Dialog.Description>
                </Dialog.Panel>
            </Dialog>

            <Dialog
                open={isCancel}
                onClose={() => setIsCancel(false)}
                size="md"
            >
                <Dialog.Panel>
                    <Dialog.Description className="rounded-none border-2 border-white">
                        <div className='flex justify-center text-center my-4 sm:px-16 px-4 fontNew'>
                            <div>
                                <h1 className='text-xl font-semibold text-black mt-6'>Cancel?</h1>
                                <p className='text-sm text-gray-500 my-3'>Are you sure you want to Cancel this <br /> Request ?</p>
                                <div className='mt-13 flex items-center mb-5'>
                                    <button className='bg-[#F4F5FA] h-[50px] sm:w-36 w-30 text-sm rounded-xl sm:mx-3 mx-2' onClick={() => setIsCancel(false)}>Close</button>
                                    <button className='bg-[#EA5F5F] h-[50px] sm:w-36 w-30 text-sm rounded-xl sm:mx-3 mx-2 text-white' onClick={handleRequestStatus}>
                                        {isLoading ? (
                                            <div className='flex justify-center'>
                                                <Circles
                                                    width={25}
                                                    height={25}
                                                    color="#fff"
                                                    ariaLabel="circles-loading"
                                                    wrapperStyle={{}}
                                                    visible={true}
                                                    wrapperClass=""
                                                />
                                            </div>
                                        ) : (
                                            "Yes, Cancel"
                                        )}

                                    </button>
                                </div>
                            </div>
                        </div>
                    </Dialog.Description>
                </Dialog.Panel>
            </Dialog>
        </div>

    )
}

export default RequestDetails