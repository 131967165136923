import React, { useEffect, useRef, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import views from "../../Assets/Image/corporate/view.png"
import FormSelect from '../../Base-Component/FormSelect/FormSelect'
import search from "../../Assets/Image/corporate/Search.png"
import nodata from "../../Assets/Image/corporate/nodata.png"
import men1 from "../../Assets/Image/corporate/men6.png"
import Dialog from '../../Base-Component/Dialog/Dialog'
import back1 from "../../Assets/Image/corporate/background2.png"
import Cookies from 'js-cookie';

function Invoices() {
  const location = useLocation()
  const navigate = useNavigate()
  const scrollRef = useRef(null);
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);
  const [selectedRows, setSelectedRows] = useState([]);

  const [searchData, setSearchData] = useState("")
  const [pageNo, setPageNo] = useState(1)
  const [totalPage, setTotalPage] = useState(3)
  const [handelSelect, setHandleSelect] = useState("")
  const [handelSelectDate, setHandleSelectDate] = useState("")
  const [handelSelectId, setHandleSelectId] = useState("")
  const [isModalOpens, setModalOpens] = useState(false)
  const [employeess, setEmployeess] = useState({})

  const [userData, setUserData] = useState([
    {
      id: "#61",
      date: "14 Mar, 2024",
      image: men1,
      name: "Cameron Williamson",
      service: "Health screening, Mental health clinic",
      total_employee: "206",
      amount: "R1200.00",
    },
    {
      id: "#62",
      date: "14 Mar, 2024",
      image: men1,
      name: "Cameron Williamson",
      service: "Health screening, Mental health clinic",
      total_employee: "206",
      amount: "R1200.00",
    },
    {
      id: "#63",
      date: "14 Mar, 2024",
      image: men1,
      name: "Cameron Williamson",
      service: "Health screening, Mental health clinic",
      total_employee: "206",
      amount: "R1200.00",
    },
    {
      id: "#64",
      date: "14 Mar, 2024",
      image: men1,
      name: "Cameron Williamson",
      service: "Health screening, Mental health clinic",
      total_employee: "206",
      amount: "R1200.00",
    },
    {
      id: "#65",
      date: "14 Mar, 2024",
      image: men1,
      name: "Cameron Williamson",
      service: "Health screening, Mental health clinic",
      total_employee: "206",
      amount: "R1200.00",
    },
    {
      id: "#66",
      date: "14 Mar, 2024",
      image: men1,
      name: "Cameron Williamson",
      service: "Health screening, Mental health clinic",
      total_employee: "206",
      amount: "R1200.00",
    },
    {
      id: "#67",
      date: "14 Mar, 2024",
      image: men1,
      name: "Cameron Williamson",
      service: "Health screening, Mental health clinic",
      total_employee: "206",
      amount: "R1200.00",
    },
    {
      id: "#68",
      date: "14 Mar, 2024",
      image: men1,
      name: "Cameron Williamson",
      service: "Health screening, Mental health clinic",
      total_employee: "206",
      amount: "R1200.00",
    },
    {
      id: "#69",
      date: "14 Mar, 2024",
      image: men1,
      name: "Cameron Williamson",
      service: "Health screening, Mental health clinic",
      total_employee: "206",
      amount: "R1200.00",
    },
  ])

  const token = localStorage.getItem("corporate_token");
  useEffect(() => {
    if (!token) {
      navigate("/corporatelogin")
    }
  }, [token])

  const renderPaginationButtonss = () => {
    const maxPagesToShow = 3;
    const buttons = [];
    const startPage = Math.max(1, pageNo - Math.floor(maxPagesToShow / 2));
    const endPage = Math.min(totalPage, startPage + maxPagesToShow - 1);

    for (let i = startPage; i <= endPage; i++) {
      buttons.push(
        <button
          key={i}
          onClick={() => handlePageChange(i)}
          className={i === pageNo ? 'px-4 py-[7px] mx-1 bg-[#EA5F5F1A] rounded-md text-[#EA5F5F]' : 'px-4 py-[7px] mx-1 text-[#EA5F5F]'}
        >
          {i}
        </button>
      );
    }
    return buttons;
  };

  const handlePageChange = (page) => {
    setPageNo(page);
  }

  const handleView = () => {
    navigate("/invoices/invoicedetails")
  }

  const startDragging = (e) => {
    setIsDragging(true);
    setStartX(e.pageX - scrollRef.current.offsetLeft);
    setScrollLeft(scrollRef.current.scrollLeft);
    document.body.style.userSelect = 'none'; // Disable text selection
  };

  const stopDragging = () => {
    setIsDragging(false);
    document.body.style.userSelect = ''; // Re-enable text selection
  };

  const onDragging = (e) => {
    if (!isDragging) return;
    e.preventDefault();
    const x = e.pageX - scrollRef.current.offsetLeft;
    const walk = (x - startX) * 2; // scroll-fast
    scrollRef.current.scrollLeft = scrollLeft - walk;
  };

  const handleSelectAll = () => {
    if (selectedRows.length === userData.length) {
      setSelectedRows([]);
    } else {
      setSelectedRows(userData.map(item => item.id));
    }
  };

  const handleSelectRow = (id) => {
    if (selectedRows.includes(id)) {
      setSelectedRows(selectedRows.filter(selectedId => selectedId !== id));
    } else {
      setSelectedRows([...selectedRows, id]);
    }
  };

  const isSelected = (id) => selectedRows.includes(id);

  return (
    <>
      <div className='grid grid-cols-12 gap-2 md:m-4 m-1 fontNew'>
        <div className="col-span-12">
          <div className='flex flex-wrap justify-between items-center'>
            <h1 className='text-2xl font-semibold sm:mb-0 mb-3'>Invoices</h1>
            <div className='flex sm:w-70 w-full h-10 px-4 rounded-xl bg-white items-center'>
              <input
                type="text"
                required
                className='bg-transparent border-none outline-none sm:w-70 w-full h-10 text-sm fontNew'
                placeholder='Search...'
                name='search_text'
                value={searchData}
                onChange={(e) => setSearchData(e.target.value)}
              />
              <img src={search} className='p-2 w-10 cursor-pointer' alt="" />
            </div>
          </div>
        </div>
        {
          userData == "" ?
            <div className='col-span-12 h-[50vh] justify-center flex items-center'>
              <img src={nodata} className='w-28' alt="" />
            </div>
            :
            <>
              <div className='col-span-12 overflow-auto'>
                <div
                  className='w-full overflow-auto'
                  onMouseDown={startDragging}
                  onMouseLeave={stopDragging}
                  onMouseUp={stopDragging}
                  onMouseMove={onDragging}
                  ref={scrollRef}
                >
                  <table className='w-full border-separate border-spacing-y-3'>
                    <tr className='bg-white'>
                      <th className='py-4 px-5 text-base font-medium whitespace-nowrap text-start first:rounded-l-lg last:rounded-r-lg'>
                        <input
                          type="checkbox"
                          checked={selectedRows.length === userData.length}
                          onChange={handleSelectAll}
                        />
                      </th>
                      <th className='py-4 px-3 text-base font-medium whitespace-nowrap text-start first:rounded-l-lg last:rounded-r-lg'>
                        <FormSelect id="page" name="page_no" className="selects-arrow-hidden outline-none w-24 text-base" value={handelSelectDate} onChange={(e) => setHandleSelectDate(e.target.value)}>
                          <option value="5">Date</option>
                          <option value="10">10</option>
                          <option value="15">15</option>
                        </FormSelect>
                      </th>
                      <th className='py-4 px-3 text-base font-medium whitespace-nowrap text-start first:rounded-l-lg last:rounded-r-lg'>
                        <FormSelect id="page" name="page_no" className="selects-arrow-hidden outline-none w-20 text-base" value={handelSelectId} onChange={(e) => setHandleSelectId(e.target.value)}>
                          <option value="5">ID</option>
                          <option value="10">10</option>
                          <option value="15">15</option>
                        </FormSelect>
                      </th>
                      <th className='py-4 px-3 text-base font-medium whitespace-nowrap text-start first:rounded-l-lg last:rounded-r-lg'>Service</th>
                      <th className='py-4 px-3 text-base font-medium whitespace-nowrap text-start first:rounded-l-lg last:rounded-r-lg'>Total Emp.</th>
                      <th className='py-4 px-3 text-base font-medium whitespace-nowrap text-start first:rounded-l-lg last:rounded-r-lg'>Amount</th>
                      <th className='py-4 px-3 text-base font-medium whitespace-nowrap text-start first:rounded-l-lg last:rounded-r-lg'>Action</th>
                    </tr>
                    {
                      userData.map((item, index) => {
                        return (
                          <tr key={index}
                            className={`font-medium ${isSelected(item.id) ? 'bg-gray-200' : 'bg-white'}`}
                          >
                            <td className='text-start px-4 py-3 first:rounded-l-lg last:rounded-r-lg'>
                              <div className='whitespace-nowrap'>
                                <input
                                  type="checkbox"
                                  checked={isSelected(item.id)}
                                  onChange={() => handleSelectRow(item.id)}
                                />
                              </div>
                            </td>
                            <td className='text-start p-4 first:rounded-l-lg last:rounded-r-lg'>
                              <div className='whitespace-nowrap'>
                                {item.date}
                              </div>
                            </td>
                            <td className='text-start p-4 first:rounded-l-lg last:rounded-r-lg'>
                              <div className='whitespace-nowrap'>
                                {item.id}
                              </div>
                            </td>
                            <td className='text-start p-4 first:rounded-l-lg last:rounded-r-lg'>
                              <div className='whitespace-nowrap'>
                                {item.service}
                              </div>
                            </td>
                            <td className='text-start p-4 first:rounded-l-lg last:rounded-r-lg'>
                              <div className='whitespace-nowrap'>
                                {item.total_employee}
                              </div>
                            </td>
                            <td className='text-start p-4 first:rounded-l-lg last:rounded-r-lg'>
                              <div className='whitespace-nowrap'>
                                {item.amount}
                              </div>
                            </td>
                            <td className='text-start p-4 first:rounded-l-lg last:rounded-r-lg'>
                              <div className='cursor-pointer' onClick={() => handleView()}>
                                <img src={views} className='lg:w-9 w-8' alt="" />
                              </div>
                            </td>
                          </tr>
                        )
                      })
                    }
                  </table>
                </div>
              </div>
              <div className='col-span-12 flex flex-wrap items-center sm:justify-between justify-center bg-white py-4 sm:px-8 px-0 rounded-lg'>
                <div className='flex items-center'>
                  <p>Show Result</p>
                  <div>
                    <FormSelect id="page" name="page_no" className="outline-none px-2 ms-3" value={handelSelect} onChange={(e) => setHandleSelect(e.target.value)}>
                      <option value="5">5</option>
                      <option value="10">10</option>
                      <option value="15">15</option>
                    </FormSelect>
                  </div>
                </div>
                <div className='flex items-center cursor-pointer'>
                  <p onClick={() => handlePageChange(pageNo - 1)} disabled={pageNo === 1}>
                    Previous
                  </p>
                  <div className="flex items-center mx-6">
                    {renderPaginationButtonss()}
                  </div>
                  <p onClick={() => handlePageChange(pageNo + 1)} disabled={pageNo === totalPage}>
                    Next
                  </p>
                </div>
              </div>
            </>
        }
      </div>

      <Dialog
        open={isModalOpens}
        onClose={() => setModalOpens(false)}
        size="md"
      >
        <Dialog.Panel>
          <Dialog.Description className="rounded-none border-2 border-white">
            <div>
              <img src={back1} className='w-400 relative' alt="" />
              <div className='flex justify-center'>
                <img src={employeess.image} className='absolute w-24 h-24 rounded-full border-2 w-16 ' alt="" />
              </div>
            </div>
            <div className='flex justify-center text-center my-4 px-16 fontNew'>
              <div>
                <h1 className='text-xl font-semibold text-black mt-6'>Delete Report?</h1>
                <p className='text-sm text-gray-500 my-3'>Are you sure want to delete {employeess.name} from the Report?</p>
                <div className='mt-16 mb-10'>
                  <button className='bg-[#F4F5FA] py-[14px] w-36 text-sm rounded-xl mx-3' onClick={() => setModalOpens(false)}>Close</button>
                  <button className='bg-[#EA5F5F] py-[14px] w-36 text-sm rounded-xl mx-3 text-white' onClick={() => setModalOpens(false)}>Yes, Delete</button>
                </div>
              </div>
            </div>
          </Dialog.Description>
        </Dialog.Panel>
      </Dialog>
    </>
  )
}

export default Invoices