import axios from "axios";
import Cookies from 'js-cookie';
import {
    ADD_COMPANY_ADDRESS_API,
    ADD_EMPLOYEE_API,
    CANCEL_REQUEST_API,
    CHANGE_PASSWORD_API,
    CREATE_REQUEST_API,
    DELETE_COMPANY_ADDRESS_API,
    EDIT_COMPANY_ADDRESS_API,
    EDIT_COMPANY_INFO,
    EDIT_EMPLOYEE_API,
    EDIT_USER_INFO,
    GET_CHAT_MESSAGES_API,
    GET_COMPANY_INFO,
    GET_DASHBOARD_API,
    GET_EMPLOYEE_API,
    GET_REPORT_DETAILS_API,
    LIST_ASSIGN_HELATHWORKER_API,
    LIST_CHAT_API,
    LIST_EMPLOYEE_API,
    LIST_NOTIFICATION_API,
    LIST_REPORTS_API,
    LIST_REQUEST_API,
    LOGOUT_CORPORATE_API,
    SEND_MESSAGES_API
} from "./Api";

axios.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem("corporate_token");
        if (token) {
            config.headers["Authorization"] = `Bearer ${token}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

const headers = {
    "Content-type": "application/json",
};

export const accountLogout = () => {
    return axios.post(LOGOUT_CORPORATE_API);
};

export const getDashboard = () => {
    return axios.get(GET_DASHBOARD_API, {
        headers: headers,
    });
};

export const getCompanyAddress = (id) => {
    return axios.get(`${GET_COMPANY_INFO}?companyId=${id}`, {
        headers: headers,
    });
};

export const editCompanyInfo = (param) => {
    return axios.put(EDIT_COMPANY_INFO, param, {
        "Content-Type": "multipart/form-data",
    });
};

export const editUserInfo = (param) => {
    return axios.put(EDIT_USER_INFO, param, {
        headers: {
            "Content-Type": "multipart/form-data",
        },
    });
};

export const listRequests = (param) => {
    return axios.get(LIST_REQUEST_API, {
        headers: headers,
        params: param
    });
};

export const createRequests = (param) => {
    return axios.post(CREATE_REQUEST_API, param, {
        headers: headers,
    });
};

export const cancelRequests = (param) => {
    return axios.post(CANCEL_REQUEST_API, param, {
        headers: headers,
    });
};

export const getEmployees = (param) => {
    return axios.get(GET_EMPLOYEE_API, {
        headers: headers,
        params: param
    });
};

export const listReports = (param) => {
    return axios.get(LIST_REPORTS_API, {
        headers: headers,
        params: param
    });
};

export const listAssignHealthworker = (param) => {
    return axios.get(LIST_ASSIGN_HELATHWORKER_API, {
        headers: headers,
        params: param
    });
};

export const addEmployees = (param) => {
    return axios.post(ADD_EMPLOYEE_API, param, {
        headers: {
            "Content-Type": "multipart/form-data",
        },
    });
};

export const editEmployees = (param) => {
    return axios.put(EDIT_EMPLOYEE_API, param, {
        headers: {
            "Content-Type": "multipart/form-data",
        },
    });
};

export const listEmployees = (param) => {
    return axios.get(LIST_EMPLOYEE_API, {
        headers: headers,
        params: param
    });
};

export const addCompanyAddress = (param) => {
    return axios.post(ADD_COMPANY_ADDRESS_API, param, {
        headers: headers,
    });
};

export const editCompanyAddress = (param) => {
    return axios.put(EDIT_COMPANY_ADDRESS_API, param, {
        headers: headers,
    });
};

export const deleteCompanyAddress = (param) => {
    return axios.delete(DELETE_COMPANY_ADDRESS_API, {
        headers: headers,
        params: param
    });
};

export const changePassword = (param) => {
    return axios.post(CHANGE_PASSWORD_API, param, {
        headers: headers,
    });
};

export const getRequestDetails = (param) => {
    return axios.get(GET_REPORT_DETAILS_API, {
        headers: headers,
        params: param
    });
};

export const getNotificationList = (param) => {
    return axios.get(LIST_NOTIFICATION_API, {
        headers: headers,
        params: param
    });
};

export const getChatList = (param) => {
    return axios.get(LIST_CHAT_API, {
        headers: headers,
        params: param
    });
};

export const getChatMessageList = (param) => {
    return axios.get(GET_CHAT_MESSAGES_API, {
        headers: headers,
        params: param
    });
};

export const sendMessages = (param) => {
    return axios.post(SEND_MESSAGES_API, param, {
        headers: headers,
    });
};